// extracted by mini-css-extract-plugin
export var hero = "_1x_k2";
export var hero_content = "_1x_p2";
export var hero_descr = "_1x_m2";
export var hero_inner = "_1x_n2";
export var hero_pic = "_1x_s2";
export var hero_stats = "_1x_b72";
export var hero_stats_item = "_1x_b82";
export var hero_stats_title = "_1x_cb2";
export var hero_stats_value = "_1x_b92";
export var hero_subtitle = "_1x_b62";
export var hero_title = "_1x_q2";